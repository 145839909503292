<template>
  <v-dialog
    v-model="dialog"
    width="800"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    persistent
    style="background-color: blue; border-radius: 30px !important"
  >
    <v-card >
      <v-card-title class="secondary py-0" style="height: 40px;">
        <span class="dialog-title">{{ tab === 1 ? $t("reject") : $t("create") }} {{ $t("appointment") }}</span>
        <v-spacer/>
        <v-icon class="d-flex justify-end" size="24px" color="primary" @click="$emit('close')">mdi-window-close</v-icon>
      </v-card-title>
      <v-card-text class=" mb-0 px-5 py-2" >
        <v-tabs
          v-model="tab"
          background-color="#E8E8E8"
          color="primary"
          grow
          hide-slider
          height="35px"
          style="border-radius: 12px"
          active-class="background-color: primary white--text"
        >
          <v-tab
            v-for="(item, i) in tabs"
            :style="
              i === 0
                ? ' border-top-left-radius: 12px; border-bottom-left-radius: 12px; text-transform: capitalize; letter-spacing: 0.0; '
                : i === 1
                ? 'border-top-right-radius: 12px; border-bottom-right-radius: 12px; text-transform: capitalize; letter-spacing: 0.0;'
                : 'text-transform: capitalize; letter-spacing: 0.0; height: 40px'
            "
            :key="i"
          >
            <span>{{ $t(item.name) }}</span>
          </v-tab>
        </v-tabs>
      </v-card-text>
      <v-form v-if="tab === 1" v-model="valid" class="pa-2">
        <v-textarea
          v-model="rejectMessage"
          v-bind:label="$t('reject-message')"
          outlined
          dense
          class="mx-3"
          :rules="rules"
          filled
          hide-details
          style="border-radius: 12px">
        </v-textarea>
        <v-card-actions class="d-flex justify-end px-0 py-3">
          <v-btn
            class="table-create-button mx-2 "
            style="padding: 15px"
            :loading="loadingReject"
            :disabled="loadingReject || !valid"
            @click="loaderReject = 'loadingReject'"
          >
            {{ $t("reject") }}
          </v-btn>
        </v-card-actions>
      </v-form>
      <v-form v-if="tab === 0" v-model="valid" class="px-5">
          <v-text-field
            v-model="appointmentTitle"
            v-bind:label="$t('appointment-title')"
            outlined
            :rules="rules"
            dense
            hide-details
            filled
            style="border-radius: 12px"
          ></v-text-field>
          <div class="d-flex py-2">
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              title=""
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="appointmentDate"
                  v-bind:label="$t('appointment-date')"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  dense
                  filled
                  style="border-radius: 12px"
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker v-model="appointmentDate" no-title scrollable :min="getMinDate()" @change="menu2 = false"></v-date-picker>
            </v-menu>
            <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" :nudge-right="40" :return-value.sync="appointmentTime" transition="scale-transition" offset-y max-width="290px" min-width="290px" :title="false">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="appointmentTime" v-bind:label="$t('appointment-time')"
                  prepend-inner-icon="mdi-clock-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  dense
                  filled
                  style="border-radius: 12px"
                  class="ml-2"
                  hide-details
                >
                </v-text-field>
              </template>
              <v-time-picker v-if="menu1" v-model="appointmentTime" :min="getMinTime()" full-width @click:minute="$refs.menu1.save(appointmentTime)" no-title format="ampm"></v-time-picker>
          </v-menu>
            <v-text-field
              v-model="appointmentPlannedDurationInMin"
              v-bind:label="$t('duration-time')"
              outlined
              :rules="rulesForMinutes"
              class="ml-2"
              dense
              hide-details
              :hint="$t('minutes')"
              type="number"
              filled
              style="border-radius: 12px"
            ></v-text-field>
          </div>
          <v-textarea
            v-model="appointmentDescription"
            :label="$t('duration-time')"
            outlined
            hide-details
            :rules="rules"
            dense
            filled
            style="border-radius: 12px"
          ></v-textarea>
        <v-card-actions class="d-flex justify-end px-0 py-3">
          <v-btn
              class="table-create-button"
              style="padding: 15px"
              :loading="loading"
              :disabled="loading || !valid"
              @click="loader = 'loading'"
          >
            {{ $t("submit") }}
          </v-btn>
        </v-card-actions>

      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
	props: ['dialog', 'item'],
	data () {
		return {
			loader: null,
			loading: false,
			loadingReject: false,
			loaderReject: false,
			tab: 0,
			tabs: [{ name: 'Accept' }, { name: 'Reject' }],
			header: 'accept',
			appointmentDate: null,
			appointmentTime: null,
			appointmentPlannedDurationInMin: 15,
			appointmentTitle: '',
			appointmentDescription: '',
			rejectMessage: '',
			valid: false,
			menu1: false,
			menu2: false,
			date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			dateFormatted: null,
			time1: null,
		};
	},
	computed: {
		computedDateFormattedMomentjs () {
			return this.date;
		},
		rules () {
			return [(v) => !!v || 'This field cannot be blank!'];
		},
		rulesForMinutes () {
			if (
				(this.appointmentPlannedDurationInMin < 0 ||
        this.appointmentPlannedDurationInMin > 59) || this.appointmentPlannedDurationInMin === ''
			) {
				return ['Please choose a number between 0-59'];
			} else return [];
		},
		dateTime () {
			return this.appointmentDate + 'T' + this.appointmentTime + ':00';
		},
	},
	watch: {
		async loader () {
			if (this.loader !== null) {
				const l = this.loader;
				this[l] = !this[l];

				await this.approveRequest()
					.then(() => {
						this[l] = false;
					})
					.catch(() => {
						this[l] = false;
					});

				this.loader = null;
			}
		},
		async loaderReject () {
			if (this.loaderReject !== null) {
				const l = this.loaderReject;
				this[l] = !this[l];

				await this.rejectRequest()
					.then(() => {
						this[l] = false;
					})
					.catch(() => {
						this[l] = false;
					});

				this.loaderReject = null;
			}
		},
	},
	methods: {
		async createAppointment () {
			const body = {
				appointmentRequestId: this.item.appointmentRequestId,
				appointmentDateTime: this.dateTime,
				appointmentPlannedDurationInMin: parseInt(
					this.appointmentPlannedDurationInMin,
				),
				appointmentTitle: this.appointmentTitle,
				appointmentDescription: this.appointmentDescription,
			};
			await this.$store
				.dispatch('appointments/createAppointment', body)
				.then(async () => {
					await this.markAsRead();
				})
				.catch(() => {});
			this.$emit('false');
			this.loading = false;
		},
		async rejectRequest () {
			this.loading = true;
			const body = {
				appointmentRequestId: this.item.appointmentRequestId,
				approveThis: false,
				responseComment: this.rejectMessage,
			};
			await this.$store
				.dispatch('appointmentrequests/approveRequest', body)
				.then(async () => {
					await this.markAsRead();
				})
				.catch(() => {});
			this.$emit('false');
			this.loading = false;
		},
		async approveRequest () {
			this.loading = true;
			const body = {
				appointmentRequestId: this.item.appointmentRequestId,
				approveThis: true,
				responseComment: '',
			};
			await this.$store
				.dispatch('appointmentrequests/approveRequest', body)
				.then(async () => {
					await this.createAppointment();
				})
				.catch(() => {});
		},
		async markAsRead () {
			await this.$store.dispatch(
				'notifications/markAsRead',
				this.item.NotificationId,
			);
		},
		getMinDate () {
			const date = new Date();
			return date.toISOString().split('T')[0];
		},
		getMinTime () {
			var date = new Date();
			if (this.appointmentDate === this.getMinDate()) {
				return `${date.getHours() + ':' + date.getMinutes()}`;
			}
		},
	},
};
</script>
