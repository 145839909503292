<template>
    <v-dialog
        v-model="dialog"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        width="1400"
        persistent
        style="background-color: blue; border-radius: 30px !important"
    >
        <v-card>
          <div style="overflow-y:hidden;">
            <v-card-title class="secondary py-1" style="height: 40px;">
              <span class="dialog-title">{{ $t("create") }}</span>
              <v-spacer />
              <v-icon small color="primary" @click="$emit('close')">mdi-window-close</v-icon>
            </v-card-title>
          </div>
            <v-card-text>
                <v-row class="mt-5 mx-3">
                    <v-col cols="4" class="col-left-alignment d-flex align-center pb-0">
                        <span style="font-size: 16px; font-weight: bold">{{$t('appointment')}} {{$t('information')}}</span>
                    </v-col>
                    <v-col class="col-left-alignment d-flex align-center pb-0 mx-n2">
                        <span style="font-size: 16px; font-weight: bold;">{{$t('doctor-calendar')}}</span>
                    </v-col>
                </v-row>
                <v-row class="mt-5 mx-3 " dense>
                    <v-col cols="4" class="px-3">
                        <v-row>
                            <v-col cols="12">
                                <v-row dense>
                                    <v-col class="ma-0 pa-0 px-1">
                                        <v-text-field
                                            v-model="appointmentTitle"
                                            :label="$t('appointment-title')"
                                            outlined
                                            :rules="rules"
                                            dense
                                            hide-details
                                            filled
                                            style="border-radius: 12px"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row v-if="userRole !== 'DOCTOR'" class="px-0 pt-2" style="height: 50px" dense>
                                    <v-col class="">
                                        <v-select :menu-props="{ bottom: true, offsetY: true }" v-model="doctorId" :label="$t('doctor')" :items="doctors" item-value="id" item-text="fullName" outlined dense flat hide-details filled style="border-radius: 12px" class="mb-3" />
                                    </v-col>
                                </v-row>
                                <v-row class=" pt-1 px-0" dense>
                                    <v-col>
                                        <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto" title="">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field hide-details v-model="appointmentDate" :label="$t('appointment-date')" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined dense filled style="border-radius: 12px"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="appointmentDate" no-title scrollable :min="getMinDate()" @change="menu2 = false"></v-date-picker>
                                            </v-menu>
                                    </v-col>
                                    <v-col >
                                        <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" :nudge-right="40" :return-value.sync="appointmentTime" transition="scale-transition" offset-y max-width="290px" min-width="290px" :title="false">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field hide-details v-model="appointmentTime" :label="$t('appointment-time')" prepend-inner-icon="mdi-clock-outline" readonly v-bind="attrs" v-on="on" outlined dense filled style="border-radius: 12px" class="pl-2"></v-text-field>
                                            </template>
                                            <v-time-picker v-if="menu1" v-model="appointmentTime" :min="getMinTime()" full-width @click:minute="$refs.menu1.save(appointmentTime)" no-title format="ampm"></v-time-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col class="ma-0 pa-0 pt-1 px-1" >
                                        <v-textarea
                                            v-model="appointmentDescription"
                                            :label="$t('description')"
                                            outlined
                                            dense
                                            filled
                                            :height="userRole === 'DOCTOR'? '180px' : '130px'"
                                            hide-details
                                            row-height="30"
                                            style="border-radius: 12px"
                                            :rows="userRole === 'DOCTOR' ? 7 : 4"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="px-3 pt-1 ">
                                <v-row>
                                    <v-col class="col-left-alignment pb-2">
                                        <span style="font-size: 16px; font-weight: bold">{{ $t("patient") }}</span>
                                    </v-col>
                                </v-row>
                                <v-row dense class="pa-0 pt-2">
                                    <v-col cols="12" class="col-left-alignment">
                                        <v-text-field
                                            hide-details
                                            v-model="search"
                                            :label="$t('find-patient')"
                                            prepend-inner-icon="mdi-magnify"
                                            outlined dense filled
                                            style="border-radius: 12px"
                                            @input="filterPatient"
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" class="py-0 my-0 pt-2">
                                        <v-list class="ma-0 pa-0 specializations-list" dense height="280px">
                                            <v-list-item-group
                                                v-model="patientId"
                                                active-class="primary--text"
                                            >
                                                <template v-for="(item) in patients">
                                                <v-list-item :key="item.id" :value="item.id">
                                                    <template v-slot:default="{ active }">
                                                        <v-list-item-content>
                                                            <v-list-item-title class="d-flex align-left pl-2 pt-1" v-text="item.firstName + ' ' + item.lastName"></v-list-item-title>
                                                        </v-list-item-content>
                                                        <v-list-item-action>
                                                            <v-icon v-if="active" color="primary">mdi-check</v-icon>
                                                        </v-list-item-action>
                                                    </template>
                                                </v-list-item>
                                                </template>
                                            </v-list-item-group>
                                        </v-list>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="8" class="pt-0 ma-0">
                        <Calendar :doctor="doctorId"></Calendar>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="mx-2 pb-5 pl-10">
                <span style="font-size: 14px" class="grey--text">{{ $t("duration-time") }} <span class="primary--text">15</span> {{ $t("minutes") }}</span>
                <v-spacer></v-spacer>
                <v-btn class="table-cancel-button" @click="$emit('close')" style="padding-left: 12px !important">
					<span color="black--text">{{ $t("cancel") }}</span>
				</v-btn>
                <v-btn
					class="table-create-button mx-2"
                    style="padding-left: 17px !important"
					:loading="loading"
					:disabled="loading || valid"
					@click="loader = 'loading'"
				>
					<span>{{ $t("save") }}</span>
				</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Calendar from '@/views/Client/components/Calendar.vue';

export default {
	components: {
		Calendar,
	},
	props: ['dialog'],
	data () {
		return {
			loader: null,
			loading: false,
			search: null,
			menu1: false,
			menu2: false,
			date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			appointmentTitle: '',
			appointmentDate: '',
			appointmentTime: '',
			appointmentDescription: '',
			patientId: undefined,
			searchPatient: null,
			patients: [],
			doctorId: undefined,
		};
	},
	computed: {
		...mapState({
			userData: (state) => state.authentication.userData,
			patientsFromRepo: state => state.users.users,
			relatedUsers: state => state.hospitals.medicalStaff,
			userDetails: state => state.authentication.userData,
			relatedPatients: (state) => state.hospitals.relatedPatients,
		}),
		...mapGetters({
			userRole: 'authentication/getRole',
		}),
		rules () {
			return [(v) => !!v || 'This field cannot be blank!'];
		},
		valid () {
			if (this.appointmentTitle === '' || this.patientId === undefined) {
				return true;
			}
			return false;
		},
		doctors () {
			if (!this.relatedUsers) return [];
			const users = this.relatedUsers.flatMap(o => o.roleId === 3 ? [{ fullName: `${o.firstName} ${o.lastName}`, id: o.id }] : []);
			return users;
		},
	},
	watch: {
		async loader () {
			if (this.loader !== null) {
				const l = this.loader;
				this[l] = !this[l];

				await this.save()
					.then(() => {
						this[l] = false;
						this.$emit('close');
					})
					.catch(() => {
						this[l] = false;
					});

				this.loader = null;
			}
		},
		searchPatient (e) {
			const itemsCopy = this.patients;
			if (e === '') {
				this.patients = this.patientsFromRepo;
				return;
			}

			this.patients = itemsCopy.filter(item => {
				return item.firstName.toLowerCase().indexOf(e.toLowerCase()) > -1;
			});
		},
	},
	async mounted () {
		await this.initialize();
	},
	methods: {
		filterPatient () {
			const searchLowerCase = this.search.toLowerCase();
			this.patients = this.relatedPatients.filter(patient => {
				const fullName = patient.firstName + ' ' + patient.lastName;
				const fullNameLowerCase = fullName.toLowerCase();
				const firstNameLowerCase = patient.firstName.toLowerCase();
				return (
					fullNameLowerCase.includes(searchLowerCase) ||
            firstNameLowerCase.includes(searchLowerCase)
				);
			});
		},
		async initialize () {
			// await this.$store.dispatch('users/getHospitalPatients')
			// 	.then(() => {
			// 		this.patients = this.patientsFromRepo.filter(patient => { return patient.medicalTeamsData.length > 0; });
			// 	});
			this.getPatients();
		},
		async getPatients () {
			const hospitalIdFromStore = this.userData.hospitalAssignedData.id;
			if (this.userRole === 'HOSPITAL_ADMIN' || this.userRole === 'DOCTOR' || this.userRole === 'NURSE') {
				// await this.$store.dispatch('hospitals/getRelatedPatientsv2', hospitalIdFromStore)
				await this.$store.dispatch('hospitals/getRelatedPatientsV3', hospitalIdFromStore)
					.then(() => {
						this.patients = this.relatedPatients;
					});
			} else {
				this.filteredUsers = [];
				this.loading = false;
			}
		},
		getMinDate () {
			const date = new Date();
			return date.toISOString().split('T')[0];
		},
		getMinTime () {
			var date = new Date();
			if (this.appointmentDate === this.getMinDate()) {
				return `${date.getHours() + ':' + date.getMinutes()}`;
			}
		},
		async save () {
			const body = {
				patientId: this.patientId,
				doctorId: this.userDetails.roleId === 3 ? this.userDetails.id : this.doctorId,
				appointmentDateTime: this.appointmentDate + 'T' + this.appointmentTime + ':00',
				appointmentPlannedDurationInMin: 15,
				appointmentTitle: this.appointmentTitle,
				appointmentDescription: this.appointmentDescription,
			};

			await this.$store.dispatch('appointments/newAppointment', body)
				.then(() => {
					this.$emit('created');
				});
		},
	},
};
</script>
