<template>
  <div>
    <CountersCard :items="items" />
    <v-row class="mx-2">
      <v-col cols="12" xl="9" lg="8" md="12">
        <NotificationsCard
            :type="'dashboard'"
            :items="items"
            :itemsPerPage="11"
            :loading="loading"
            :userTimeZone="userTimeZone"
            @filter="filterData"
            @rerender="getMyNotifications"
            @recurrence="surveyRecurrence"
        />
      </v-col>
      <v-col class="pt-0" cols="12" xl="3" lg="4" md="12">
        <AppointmentsCard :userTimeZone="userTimeZone" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import {
	abnormalNotification,
	allNotification,
	missedNotificationWhite,
	messagesNotificationWhite,
	appointmentsWhite,
} from '@/assets/';
import AppointmentsCard from '@/views/Client/components/DashboardComponents/AppointmentsCard.vue';
import CountersCard from '@/views/Client/components/DashboardComponents/CountersCard.vue';
import NotificationsCard from '@/views/Client/components/DashboardComponents/NotificationsCard.vue';
export default {
	components: { AppointmentsCard, CountersCard, NotificationsCard },
	data () {
		return {
			loading: true,
		};
	},
	computed: {
		...mapState({
			userDetails: (state) => state.authentication.userData,
			filterCase: (state) => state.authentication.filterCase,
			notifications: (state) => state.notifications.allNotifications,
			abnormalNotifications: (state) =>
				state.notifications.abnormalMeasurements,
			reminders: (state) => state.notifications.reminders,
			missed: (state) => state.notifications.missMeasurements,
			chatNotifications: (state) => state.notifications.chatNotifications,
			appointmentRequests: (state) => state.notifications.appointmentRequests,
			mentalHealth: (state) => state.notifications.mentalHealth,
			surveyRecurrenceId: state => state.mentalhealth.surveyRecurrenceId,
		}),
		...mapGetters({ roleName: 'authentication/getRole' }),
		items () {
			var items = this.roleName === 'DOCTOR' ? [
				{
					text: this.$t('all-notifications'),
					img: allNotification,
					cardColor: '#6600FF',
					read: this.notifications.filter((a) => {
						return a.IsRead;
					}).length,
					all: this.notifications.length,
				},
				{
					text: this.$t('messages'),
					img: messagesNotificationWhite,
					cardColor: '#3A3A3A',
					read: this.chatNotifications.filter((a) => {
						return a.IsRead;
					}).length,
					all: this.chatNotifications.length,
				},
				{
					text: this.$t('abnormal'),
					img: abnormalNotification,
					cardColor: '#D30202',
					read: this.abnormalNotifications.filter((a) => {
						return a.IsRead;
					}).length,
					all: this.abnormalNotifications.length,
				},
				{
					text: this.$t('missed'),
					img: missedNotificationWhite,
					cardColor: '#FF931E',
					read: this.missed.filter((a) => {
						return a.IsRead;
					}).length,
					all: this.missed.length,
				},
				{
					text: this.$t('appointment-requests'),
					img: appointmentsWhite,
					cardColor: '#006837',
					read: this.appointmentRequests.filter((a) => {
						return a.IsRead;
					}).length,
					all: this.appointmentRequests.length,
				},
				{
					text: this.$t('mental-health'),
					img: appointmentsWhite,
					cardColor: '#006837',
					read: this.mentalHealth.filter((a) => {
						return a.IsRead;
					}).length,
					all: this.mentalHealth.length,
				},
			]
				: [
					{
						text: 'All Notifications',
						img: allNotification,
						cardColor: '#6600FF',
						read: this.notifications.filter((a) => {
							return a.IsRead;
						}).length,
						all: this.notifications.length,
					},
					{
						text: this.$t('abnormal'),
						img: abnormalNotification,
						cardColor: '#D30202',
						read: this.abnormalNotifications.filter((a) => {
							return a.IsRead;
						}).length,
						all: this.abnormalNotifications.length,
					},
					{
						text: this.$t('missed'),
						img: missedNotificationWhite,
						cardColor: '#FF931E',
						read: this.missed.filter((a) => {
							return a.IsRead;
						}).length,
						all: this.missed.length,
					},
					{
						text: this.$t('messages'),
						img: messagesNotificationWhite,
						cardColor: '#3A3A3A',
						read: this.chatNotifications.filter((a) => {
							return a.IsRead;
						}).length,
						all: this.chatNotifications.length,
					},
				];
			return items;
		},

		userTimeZone () {
			return this.userDetails?.userTimezone;
		},
	},
	mounted () {
		if (this.$route.params.type === 'endCall') {
			location.reload();
		}
		this.getMyNotifications();
	},
	methods: {
		surveyRecurrence () {
			if (this.surveyRecurrenceId !== null) {
				this.$store.dispatch('mentalhealth/getSurveyRecurrence', this.surveyRecurrenceId);
			}
		},
		async filterData (item) {
			this.loading = true;
			await this.$store.dispatch('notifications/ALL_NOTIFICATIONS', item);
			this.loading = false;
		},
		async getMyNotifications () {
			this.loading = true;
			await this.$store.dispatch(
				'notifications/ALL_NOTIFICATIONS',
				this.filterCase,
			);
			this.loading = false;
		},
	},
};
</script>
