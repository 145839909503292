<template>
  <v-dialog
    v-model="dialog"
    width="400"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    persistent
    style="background-color: blue; border-radius: 30px !important"
  >
    <v-card flat>
      <v-card-title class="secondary py-1" style="height: 40px">
        <span class="dialog-title"
          >{{ $t("appointment") }} {{ $t("details") }}</span
        >
        <v-spacer />
        <v-icon small color="primary" @click="$emit('close')"
          >mdi-window-close</v-icon
        >
      </v-card-title>
      <v-form v-model="valid" class="pa-8">
        <span
          class="black--text d-flex align-left"
          style="font-size: 20px; font-weight: bold"
          >{{ details.appointmentTitle }}</span
        >
        <span class="grey--text d-flex align-left">{{
          details.appointmentDescription
        }}</span>
        <v-divider class="my-2" />
        <span class="grey--text d-flex align-left">{{$t('patient')}}</span>
        <span
          class="black--text d-flex align-left"
          style="font-size: 16px; font-weight: bold"
          >{{ details.patientData.firstName }}
          {{ details.patientData.lastName }}</span
        >
        <div class="d-flex">
          <div>
            <span class="grey--text d-flex align-left">{{$t('date')}} & {{$t('time')}}</span>
            <span
              class="black--text d-flex align-left"
              style="font-size: 16px; font-weight: bold"
              >{{
                details.appointmentDateTime
                  | dateformat("timestamp_med", timeLocalization, details.appointmentTimezone)
              }}</span
            >
          </div>
          <v-spacer />
          <v-card
            flat
            color="primary"
            class="pa-2 mb-7 mx-2 d-flex align-center justify-center"
            @click="goToRoom"
          >
            <img height="24px" width="24px" :src="videoIcon" />
          </v-card>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import { videocallWhite } from '@/assets';
export default {
	props: ['dialog', 'details'],
	data () {
		return {
			valid: false,
			videoIcon: videocallWhite,
			timeLocalization: this.$i18n.locale,
		};
	},
	methods: {
		goToRoom () {
			// this.$router.push({
			//   name: "Room4",
			//   params: {
			//     details: this.details,
			//     roomId: `${this.details.videoRoomId}`,
			//     roomName: this.details.appointmentTitle,
			//   },
			// });
			this.$router.push({
				name: 'Room6',
				params: {
					id: `${this.details.videoRoomId}`,
					patientId: this.details.patientId,
				},
			});
		},
	},
};
</script>
