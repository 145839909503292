<template>
  <v-card flat style="border-radius: 12px" elevation="6">
    <v-row>
      <v-col class="d-flex align-center" cols="4">
        <span class="font-weight-bold mx-8" style="font-size: 16px"
        >{{$t('notifications')}}</span
        >
      </v-col>
      <v-col class="px-7" cols="8">
        <v-tabs
            :show-arrows="true"
            v-model="tab"
            background-color="#E8E8E8"
            color="primary"
            grow
            dense
            class=""
            hide-slider
            height="30px"
            style="border-radius: 8px"
            active-class="background-color: primary white--text"
        >
          <v-tab
              v-for="(item, i) in items"
              :style="
              i === 0
                ? ' border-top-left-radius: 8px; border-bottom-left-radius: 8px; height: 30px; text-transform: capitalize; letter-spacing: 0.0;'
                : i === items.length - 1
                ? 'border-top-right-radius: 8px; border-bottom-right-radius: 8px; height: 30px; text-transform: capitalize; letter-spacing: 0.0;'
                : 'text-transform: capitalize; letter-spacing: 0.0; height: 30px'
            "
              :key="i"
          >
            <span>{{ item.text }}</span>
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-card-text>
      <v-row class="d-flex justify-end">
        <v-col cols="3">
          <v-select
              label="Filter"
              single-line
              hide-details
              :value="filterCase"
              :items="filterCases"
              height="30px"
              dense
              background-color="#E8E8E8"
              rounded
              style="border-radius: 8px !important"
              @change="filterData"
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search-notifications')"
              single-line
              hide-details
              height="30px"
              dense
              background-color="#E8E8E8"
              rounded
              style="border-radius: 8px"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
        :loading="loading"
        :headers="headers"
        :search="search"
        :no-data-text="$t('no-data-available')"
        :loading-text="$t('loading')"
        :items="loading ? [] : notificationsTable"
        item-key="notificationId"
        class="elevation-0"
        style="border-radius: 12px; cursor: pointer"
        :height="test"
        fixed-header
        dense
        :items-per-page="-1"
        @click:row="rowClick"
        :footer-props="{
          itemsPerPageText: this.$t('row'),
          itemsPerPageAllText: this.$t('all'),
        }"
    >
      <template v-slot:[`item.NotificationTypeId`]="{ item }">
        <!-- <span> {{ item.NotificationTypeId }}</span> -->
        <img
            height="24px"
            width="24px"
            :src="getImageForNotification(item.NotificationTypeId)"
        />
      </template>
      <template v-slot:[`item.fromUserFullname`]="{ item }">
        <span :style="getDataTableStyle(item.IsRead)">
          {{ item.fromUserFullname }}</span
        >
      </template>
      <template v-slot:[`item.NotificationTimestamp`]="{ item }">
        <span :style="getDataTableStyle(item.IsRead)">
          {{
            item.NotificationTimestamp
                | dateformat("timestamp_med", timeLocalization, userTimeZone)
          }}</span
        >
      </template>
		<template v-if="language === 'MessageToSend'" v-slot:[`item.MessageToSend`]="{ item }">
			<span :style="getDataTableStyle(item.IsRead)">
				{{ item.MessageToSend }}
				<template v-if="item.AttachedFileExtension">
				<!-- Display attachment icon when AttachedFileExtension is not null -->
				<v-icon v-if="item.AttachedFileExtension">mdi-paperclip</v-icon>
				</template>
			</span>
		</template>
      <template v-else v-slot:[`item.MessageToSendEsp`]="{ item }">
        <span :style="getDataTableStyle(item.IsRead)">{{
            item.MessageToSendEsp
          }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
            v-if="item.NotificationTypeId === 7"
            class="mx-n1"
            @click="rejectAppointment(item)"
            :disabled="item.IsRead"
            icon
        ><v-icon color="red">mdi-close</v-icon></v-btn
        >
        <v-btn
            v-if="item.NotificationTypeId === 7"
            icon
            :disabled="item.IsRead"
            @click="acceptAppointment(item)"
        ><v-icon color="green">mdi-check</v-icon></v-btn
        >
        <v-btn class="mx-3" v-if="item.NotificationTypeId !== 7" disabled icon
        ><v-icon color="red">mdi-null</v-icon></v-btn
        >
      </template>
    </v-data-table>
    <CreateAppointmentDialog
        v-if="appointmentDialog"
        :dialog="appointmentDialog"
        :item="notificationItem"
        @close="appointmentDialog = false"
        @false="appointmentProcessed"
    />
    <abnormal-notification-dialog v-if="abnormalNotificationDialog" :dialog="abnormalNotificationDialog" :item="notificationItem" @false="abnormalProcessed" @close="abnormalNotificationDialog = false" />
  </v-card>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import CreateAppointmentDialog from '@/views/dialogs/CreateAppointmentDialog.vue';
import AbnormalNotificationDialog from '../../../dialogs/AbnormalNotificationDialog.vue';
import {
	abnormalNotificationRed,
	missedNotificationColor,
	messagesNotificationBlack,
	appointmentsBlack,
	reminder,
} from '@/assets';
export default {
	components: { CreateAppointmentDialog, AbnormalNotificationDialog },
	props: ['items', 'loading', 'userTimeZone', 'itemsPerPage', 'userId', 'type'],
	data () {
		return {
			tab: 0,
			search: '',
			appointmentDialog: false,
			notificationItem: null,
			abnormalNotificationDialog: false,
			appointments_black: appointmentsBlack,
			messages_notification_black: messagesNotificationBlack,
			missed_notification_color: missedNotificationColor,
			abnormalNotification: abnormalNotificationRed,
			reminder: reminder,
			filterCases: [
				{ text: this.$t('today'), value: 'TODAY' },
				{ text: this.$t('last-7-days'), value: 'LAST7' },
				{ text: this.$t('last-14-days'), value: 'LAST14' },
				{ text: this.$t('last-30-days'), value: 'LAST30' },
				{ text: this.$t('all'), value: 'ALL' },
			],
			timeLocalization: this.$i18n.locale,
		};
	},
	computed: {
		...mapState({
			userDetails: (state) => state.authentication.userData,
			filterCase: (state) => state.authentication.filterCase,
			notifications: (state) => state.notifications.allNotifications,
			abnormalNotifications: (state) => state.notifications.abnormalMeasurements,
			reminders: (state) => state.notifications.reminders,
			missed: (state) => state.notifications.missMeasurements,
			chatNotifications: (state) => state.notifications.chatNotifications,
			appointmentRequests: (state) => state.notifications.appointmentRequests,
			mentalHealth: state => state.notifications.mentalHealth,
		}),
		...mapGetters({
			pageHeight: 'pageHeight',
			roleName: 'authentication/getRole',
		}),
		test () {
			switch (this.$vuetify.breakpoint.name) {
			case 'xs':
				return 220;
			case 'sm':
				return 400;
			case 'md':
				return window.innerHeight - 500;
			case 'lg':
				if (this.type === 'dashboard') {
					return window.innerHeight - 400;
				} else return (window.innerHeight / 2) - 180;
			case 'xl':
				if (this.type === 'dashboard') {
					return window.innerHeight - 400;
				} else return (window.innerHeight / 2) - 180;
			default:
				return 0;
			}
		},
		notificationsTable () {
			if (this.$route.name === 'Patient Details') {
				return this.notificationsTabs(this.tab)?.filter((n) => {
					return n.FromUserId === parseInt(this.$route.params.id);
				});
			} else return this.notificationsTabs(this.tab);
		},
		language () {
			return this.$i18n.locale === 'en' ? 'MessageToSend' : 'MessageToSendEsp';
		},
		headers () {
			return [
				{ text: '', value: 'NotificationTypeId', sortable: false, width: '0%' },
				{ text: this.$t('patient-name'), value: 'fromUserFullname', width: '15%' },
				// { text: 'Primary Doctor', value: 'fromUserFullname' },
				// { text: 'Facility', value: 'fromUserFullname' },
				{ text: this.$t('Message'), value: this.language, width: '60%' },
				{ text: this.$t('time'), value: 'NotificationTimestamp' },
			];
		},
	},
	methods: {
		getDataTableStyle (isReaded) {
			if (isReaded) {
				return 'font-size: 12px';
			} else {
				return 'font-weight: 600; font-size: 12px';
			}
		},
		getImageForNotification (type) {
			switch (type) {
			case 1:
				return this.messages_notification_black;
			case 3:
				return this.abnormalNotification;
			case 4:
				return this.reminder;
			case 5:
				return this.missed_notification_color;
			case 6:
				return;
			case 7:
				return this.appointments_black;
			case 8:
				return this.appointments_black;
			case 12:
				return this.appointments_black;
			case 13:
				return this.appointments_black;
			}
		},
		rowClick (item) {
			this.$store.dispatch('mentalhealth/updateFiltersRecurrence', item.surveyRecurrencId);
			// this.$emit('recurrence');
			if (item.NotificationTypeId === 7 && this.$route.name === 'Dashboard') {
				if (item.IsRead) {
					return;
				}
				this.notificationItem = item;
				this.appointmentDialog = true;
			} else if (item.NotificationTypeId === 1 && this.$route.name === 'Dashboard') {
				if (item.IsGroupNotification === true) {
					this.$router.push({ path: `/patientdetails/${item.PatientRelatedId}` });
				} else {
					this.$router.push({ path: `/patientdetails/${item.FromUserId}` });
				}
			} else if (item.NotificationTypeId === 3 && this.$route.name === 'Dashboard') {
				this.$router.push({ path: `/patientdetails/${item.FromUserId}` });
			} else if ((item.NotificationTypeId === 12 || item.NotificationTypeId === 13) && this.$route.name === 'Dashboard') {
				this.$router.push({ path: '/mentalhealthpatient/' });
			} else if (item.NotificationTypeId === 3 && this.$route.name !== 'Dashboard') {
				this.notificationItem = item;
				this.abnormalNotificationDialog = true;
			}
		},
		appointmentProcessed () {
			this.appointmentDialog = false;
			this.$emit('rerender');
		},
		abnormalProcessed () {
			this.abnormalNotificationDialog = false;
			this.$emit('rerender');
		},
		acceptAppointment (item) {
			this.notificationItem = item;
			this.appointmentDialog = true;
		},
		async filterData (item) {
			this.$store.dispatch('authentication/changeFilterCase', item);
			this.$emit('filter', item);
		},
		notificationsTabs (id) {
			var tab = this.roleName === 'DOCTOR' ? { 1: this.notifications, 2: this.chatNotifications, 3: this.abnormalNotifications, 4: this.missed, 5: this.appointmentRequests, 6: this.mentalHealth } : { 1: this.notifications, 2: this.abnormalNotifications, 3: this.missed, 4: this.chatNotifications };
			// var tab = this.roleName === 'DOCTOR' ? { 1: this.notifications, 2: this.chatNotifications, 3: this.abnormalNotifications, 4: this.missed, 5: this.appointmentRequests } : { 1: this.notifications, 2: this.abnormalNotifications, 3: this.missed, 4: this.chatNotifications };
			return tab[id + 1];
		},
	},
};
</script>
