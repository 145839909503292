<template>
	<v-card  style="border-radius: 12px;" elevation="6">
		<v-card :height="cardHeight" style="border-radius: 12px;">

			<div>
<!--				<div class="d-flex align-center">-->
<!--					<div class="mx-2 mb-8 ml-5">-->
<!--						<v-menu hidden="true" bottom origin="center center" transition="scale-transition" :close-on-click="closeOnClick" offset-y rounded="lg">-->
<!--							<template v-slot:activator="{ on, attrs, value }">-->
<!--								<v-btn elevation="0" color="secondary" style="border-radius: 8px; height: 30px" v-bind="attrs" v-on="on">-->
<!--									<span class="d-flex align-left main_black&#45;&#45;text" style="font-size: 15px; text-transform: capitalize; font-weight: 600 !important; text-indent: 0 !important; letter-spacing: 0 !important">{{ sectionTitle }}</span>-->
<!--									<v-icon v-if="!value" color="primary" >mdi-menu-down</v-icon>-->
<!--									<v-icon v-else color="primary">mdi-menu-up</v-icon>-->
<!--								</v-btn>-->
<!--							</template>-->

<!--							<v-list nav dense v-model="tab">-->
<!--								<v-list-item-group v-model="selectedSection" color="primary">-->
<!--									<v-list-item v-for="(item, index) in items2" :key="index">-->
<!--										<v-list-item-title>{{ item.title }}</v-list-item-title>-->
<!--									</v-list-item>-->
<!--								</v-list-item-group>-->
<!--							</v-list>-->
<!--						</v-menu>-->
<!--					</div>-->
<!--				</div>-->
        <div style="display:flex; align-items: center; justify-content: space-between" class="pa-2 overflow-y-hidden">
          <div>
            <v-card-actions>
              <v-btn class="table-create-button mt-n15" elevation="0" color="primary" label="Test" @click="createDialog = true">
                <img height="18px" width="18px" :src="videocallWhite" />
                <span style="text-transform: capitalize;" class="pa-2">{{ $t("new-appointment") }}</span>
              </v-btn>
            </v-card-actions>
          </div>
          <v-card flat color="#E8E8E8" style="border-radius: 8px" class="mx-2 px-2 py-1" >
            <span>{{ new Date().toLocaleString(`${this.$i18n.locale}`, { weekday: "long", }) }} </span><br />
            <span class="font-weight-bold primary--text" style="font-size: 28px">{{ new Date().getDate() }}</span><br />
            <span>{{ new Date().toLocaleDateString(`${this.$i18n.locale}`, {month: 'short'}) }}, {{ new Date().getFullYear() }}</span>
          </v-card>
          </div>
        <v-tabs v-if="selectedSection === 0" v-model="tab" background-color="#E8E8E8" color="primary" grow class="pa-4" hide-slider height="30px" style="border-radius: 8px" active-class="background-color: primary white--text">
          <v-tab v-for="(item, i) in items" :style=" i === 0 ? ' border-top-left-radius: 8px; border-bottom-left-radius: 8px; height: 30px; text-transform: capitalize; letter-spacing: 0.0;' : i === items.length - 1
						? 'border-top-right-radius: 8px; border-bottom-right-radius: 8px; height: 30px; text-transform: capitalize; letter-spacing: 0.0;' : 'text-transform: capitalize; letter-spacing: 0.0; height: 40px'" :key="i">
            <span>{{ item.text }}</span>
          </v-tab>
        </v-tabs>
        </div>
			<v-select
          :menu-props="{ bottom: true, offsetY: true }"
				v-if="roleName === 'NURSE'"
				v-model="doctorId"
                single-line
                hide-details
                filled
                dense
                background-color="#E8E8E8"
                rounded
				label="Doctor"
				:items="doctors"
				item-value="id"
				item-text="fullName"
				style="border-radius: 12px"
				class="mb-3 px-4"
				clearable
			/>
			<div v-if="appointmentsLength === 0 && tab === 0 && selectedSection === 0" class="pa-6 mx-2" style="backgroundcolor: transparent; border: 1px dashed #E8E8E8; border-radius: 5px;">
				<span class="primary--text pa-2">{{$t('noAppointmentsForToday')}}</span>
			</div>
			<div v-if="selectedSection === 0" style="overflow-y:auto; max-height:75%;">

				<v-card-text class="my-n3" v-for="(item, i) in appointments" :key="i">
							<v-card elevation="1" color="secondary" style="border-radius: 12px !important; " flat>
								<v-row dense>
									<v-col cols="4">
										<v-row dense class="pt-2">
											<v-col class="pa-0 ma-0">
												<v-avatar color="#f2f0f0" size="46">
													<img class="mt-1" :src="patientBlack" height="24px" width="24px" />
												</v-avatar>
											</v-col>
										</v-row>
										<v-row dense>
											<v-col class="pa-0 ma-0 pt-2">
												<span class="font-weight-bold primary--text" style="font-size: 21px" >{{ item.appointmentDateTime | dateformat("time_am_pm", timeLocalization, userTimeZone) }}</span>
											</v-col>
										</v-row>
										<v-row dense>
											<v-col class="pa-0 ma-0">
												<span class="font-weight-bold main_black--text" style="font-size: 12px" >{{ item.appointmentPlannedDurationInMin }} min</span>
											</v-col>
										</v-row>
									</v-col>
									<v-col cols="6" class="col-left-alignment">
										<v-row dense class="pt-2" @click="goToPatientDetails(item.patientId)" style="cursor: pointer;">
											<v-col>
												<span class="font-weight-bold primary--text" style="font-size: 16px">{{ item.patientData.firstName }} {{ item.patientData.lastName }} </span>
<!--                        <span>{{item.patientData}}</span>-->
											</v-col>
										</v-row>
										<v-row dense>
											<v-col class="pa-0 ma-0">
												<span>{{ item.patientData.phone }}</span>
											</v-col>
											<v-col v-if="item.appointmentStatusId !== 1 && roleName === 'DOCTOR'" cols="1" class="pa-0 ma-0 pl-4">
												<v-btn small elevation="0" fab color="primary" class="pa-2" @click.stop="goToRoom(item)">
													<img height="21px" width="21px" :src="videocallWhite" />
												</v-btn>
											</v-col>
										</v-row>
										<v-row dense>
											<v-col class="pa-0 ma-0">
<!--												<span class="font-weight-bold">{{ item.appointmentTitle }}</span>-->
                      <span class="font-weight-bold">{{item.appointmentTitle}}</span>
											</v-col>
										</v-row>
									</v-col>
								</v-row>
							</v-card>
				</v-card-text>
			</div>
			<div v-else>
				<v-card-text>
					<v-list disabled>
						<v-list-item-group color="primary">
							<v-list-item v-for="(item, i) in appointments" :key="i">
								<v-list-item-content>
									<v-row>
										<v-col class="col-left-alignment">
											<v-list-item-title><span style="font-weight: 600">{{ item.patientData.firstName }} {{ item.patientData.lastName }} {{item.patientData.birthdate}}</span></v-list-item-title>
										</v-col>
										<v-col class="col-left-alignment">
											<v-list-item-title v-text="item.appointmentTitle"></v-list-item-title>
										</v-col>
										<v-col class="col-right-alignment">
											<v-list-item-title><span style="font-weight: 600">{{ item.appointmentDateTime| dateformat("time_am_pm", timeLocalization, userTimeZone) }}</span></v-list-item-title>
										</v-col>
									</v-row>
									<v-divider></v-divider>
								</v-list-item-content>
							</v-list-item>
						</v-list-item-group>
					</v-list>
				</v-card-text>
			</div>
			<AppointmentDetailsDialog v-if="dialog" :dialog="dialog" :details="appointmentDetails" @close="dialog = false"/>
			<NewAppointmentDialog v-if="createDialog" :dialog="createDialog" @close="createDialog = false" @created="initialize"/>
		</v-card >
	</v-card>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { DateTime } from 'luxon';
import AppointmentDetailsDialog from '@/views/dialogs/AppointmentDetailsDialog.vue';
import NewAppointmentDialog from '@/views/dialogs/NewAppointmentDialog.vue';
import { patientBlack, videocallWhite } from '@/assets';
import Blink from 'vue-blink';
import { formatterFilter } from '@/utils/luxon-formater';

var Vue = null;
export default {
	// eslint-disable-next-line vue/no-unused-components
	components: { AppointmentDetailsDialog, Blink, NewAppointmentDialog },
	props: ['userTimeZone'],
	data () {
		return {
			dialog: false,
			padless: false,
			createDialog: false,
			appointmentDetails: null,
			DateTime: DateTime,
			snackStatus: false,
			title: '',
			snackColor: '',
			noDevices: false,
			tab: 0,
			items: [{ id: 1, text: this.$t('upcoming') }, { id: 1, text: this.$t('finished') }],
			patientBlack: patientBlack,
			doctorId: null,
			items2: [
				{ title: this.$t('appointments') },
				{ title: this.$t('video-calls') },
			],
			closeOnClick: true,
			selectedSection: 0,
			sectionTitle: this.$t('appointments'),
			date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			menu: false,
			videocallWhite: videocallWhite,
			timeLocalization: this.$i18n.locale,
		};
	},
	computed: {
		...mapState({
			appointmentsForTodayFromRepo: (state) => state.appointments.appointmentsForToday,
			loggedUser: (state) => state.authentication.userData,
			relatedUsers: state => state.hospitals.medicalStaff,
		}),
		...mapGetters({
			roleName: 'authentication/getRole',
			delay: 'authentication/getDelay',
		}),
		localAttrs () {
			const attrs = {};

			if (this.variant === 'default') {
				attrs.absolute = false;
				attrs.fixed = false;
			} else {
				attrs[this.variant] = true;
			}
			return attrs;
		},
		filterAppointments () {
			return this.filterAppointmentsMethod(this.appointmentsForTodayFromRepo);
		},
		appointmentsLength () {
			const number = this.filterAppointments?.filter(a => {
				return a.appointmentEndedAt === null;
			});
			return number?.length;
		},
		appointments () {
			if (this.selectedSection === 1) {
				const filteredAppointments = this.appointmentsForTodayFromRepo?.filter(a => { return a.appointmentType === 'INIT_VIDEO_CALL'; });
				if (this.doctorId) {
					return filteredAppointments.filter(app => { return app.doctorId === this.doctorId; });
				}
				return filteredAppointments.reverse();
			}
			if (this.tab === 0) {
				const filteredAppointments = this.appointmentsForTodayFromRepo?.filter(a => { return (a.appointmentStatusId === -1 || a.appointmentStatus === -1 || a.appointmentStatusId === 2) && a.appointmentType !== 'INIT_VIDEO_CALL'; });
				if (this.doctorId) {
					return filteredAppointments.filter(app => { return app.doctorId === this.doctorId; });
				}
				return filteredAppointments;
			} else {
				const filteredAppointments = this.appointmentsForTodayFromRepo?.filter(a => { return a.appointmentStatusId === 1 && a.appointmentType !== 'INIT_VIDEO_CALL'; });
				if (this.doctorId) {
					return filteredAppointments.filter(app => { return app.doctorId === this.doctorId; });
				}
				return filteredAppointments;
			}
		},
		cardHeight () {
			switch (this.$vuetify.breakpoint.name) {
			case 'xs':
				return window.innerHeight - 500;
			case 'sm':
				return window.innerHeight - 500;
			case 'md':
				return window.innerHeight - 200;
			case 'lg':
				return window.innerHeight - 230;
			case 'xl':
				return window.innerHeight - 230;
			default:
				return 0;
			}
		},
		doctors () {
			if (!this.relatedUsers) return [];
			const users = this.relatedUsers.flatMap(o => o.roleId === 3 ? [{ fullName: `${o.firstName} ${o.lastName}`, id: o.id }] : []);
			return users;
		},
	},
	watch: {
		selectedSection (val) {
			if (val === 0) {
				this.sectionTitle = this.$t('appointments');
			} else {
				this.sectionTitle = this.$t('video-calls');
			}
		},
	},
	async mounted () {
		await this.initialize();
	},
	methods: {
		formatedDate (date, type, timezone) {
			return formatterFilter(date, type, this.timeLocalization, timezone);
		},
		diffMinutes (dt2, dt1) {
			var diff = (dt2.getTime() - dt1.getTime()) / 1000;
			diff /= 60;
			return Math.round(diff) * -1;
		},
		goToPatientDetails (id) {
			this.$router.push({
				name: 'Patient Details',
				params: { id: `${id}` },
			});
		},
		filterAppointmentsMethod (n) {
			const sortedAppointments = n?.sort((obj1, obj2) => {
				const obj1Temp = new Date(obj1.appointmentDateTime);
				const obj2Temp = new Date(obj2.appointmentDateTime);
				return obj1Temp - obj2Temp;
			});
			return sortedAppointments;
		},
		async goToRoom (item) {
			Vue = this;
			Vue.snackStatus = false;
			await navigator.mediaDevices
				.getUserMedia({ audio: true })
				.then(function () {
					Vue.noDevices = false;
				})
				.catch(function (err) {
					Vue.noDevices = true;
					Vue.$store.commit('alerts/add', {
						id: 'checkDevices',
						type: 'error',
						color: 'main_red',
						message: err,
					});
					this.$store.dispatch('alerts/clearWithDelay', this.delay);
				});
			if (Vue.noDevices) {
				Vue = null;
				return;
			}
			Vue = null;
			this.$router.push({
				name: 'VideoRoom',
				params: {
					id: `${item.videoRoomId}`,
					userId: `${item.patientId}`,
					appointmentId: item.appointmentId ? item.appointmentId : item.id,
					token: localStorage.sessionToken,
					needVideoRecording: item.needVideoRecording,
					videotrack: true,
				},
			});
		},
		getMonthName (i) {
			var a = [
				{ name: 'Jan' },
				{ name: 'Feb' },
				{ name: 'Mar' },
				{ name: 'Apr' },
				{ name: 'May' },
				{ name: 'Jun' },
				{ name: 'Jul' },
				{ name: 'Aug' },
				{ name: 'Sep' },
				{ name: 'Oct' },
				{ name: 'Nov' },
				{ name: 'Dec' },
			];
			return a[i].name;
		},
		async initialize () {
			if (this.roleName === 'DOCTOR') {
				await this.$store.dispatch('appointments/getAppointmentsForToday', this.loggedUser.id);
			} else {
				// const hospitalid = this.loggedUser.medicalTeamsData[0].hospitalId;
				const hospitalid = this.loggedUser.hospitalAssignedData.id;
				await this.$store.dispatch('appointments/getAppointmentsByHospitalId', hospitalid || null);
				await this.$store.dispatch('hospitals/getRelatedMedicalStaff', hospitalid);
			}
		},
	},
};
</script>
<style>
::-webkit-scrollbar {
  width: 0px !important;
}
.card-outter {
  padding-bottom: 50px;
}
.card-actions {
  position: absolute;
  bottom: 0;
}
</style>
