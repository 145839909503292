<template>
    <v-row >
        <v-col class="pt-0">
            <v-sheet height="64" class="pt-3" >
              <v-row class="" >
                <v-col class="d-flex justify-start">
                    <v-btn class="table-create-button " @click="setToday">
                      {{ $t("today") }}
                    </v-btn>
                  </v-col>
                <v-col class="d-flex justify-center align-center">
                    <v-btn fab text small color="primary" @click="prev">
                        <v-icon small>
                        mdi-chevron-left
                        </v-icon>
                    </v-btn>
                    <v-btn fab text small color="primary" @click="next">
                        <v-icon small>
                        mdi-chevron-right
                        </v-icon>
                    </v-btn>
                    <span style="font-size: 18px" v-if="$refs.calendar">
                         {{ $refs.calendar.title }}
                    </span>
                </v-col>
                <v-col class="d-flex justify-end">
                    <v-menu max-height="220px" :rounded="true" bottom right offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="table-cancel-button" v-bind="attrs" v-on="on">
                                <span>{{ typeToLabel[type] }}</span>
                                <v-icon right>mdi-menu-down</v-icon>
                            </v-btn>
                        </template>
                        <v-list class="text-left">
                            <v-list-item @click="type = 'day'">
                                <v-list-item-title>{{$t('day')}}</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'week'">
                                <v-list-item-title>{{$t('week')}}</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'month'">
                                <v-list-item-title>{{$t('month')}}</v-list-item-title>
                            </v-list-item>
<!--                            <v-list-item @click="type = '4day'">-->
<!--                                <v-list-item-title>{{$t('4-days')}}</v-list-item-title>-->
<!--                            </v-list-item>-->
                        </v-list>
                    </v-menu>
                </v-col>
              </v-row>
            </v-sheet>
            <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
            <v-sheet v-else height="600" class="pt-2">
                <v-calendar
                    ref="calendar"
                    v-model="focus"
                    color="primary"
                    :events="events"
                    :event-color="getEventColor"
                    :type="type"
                    @click:event="showEvent"
                    @click:more="viewDay"
                    @click:date="viewDay"
                    @change="getDoctorAppointments(doctor, doctorId)"
                    :locale="language"
                ></v-calendar>
                <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
                <v-card color="grey lighten-4" min-width="350px" min-height="" flat>
                    <v-toolbar height="45px" :color="selectedEvent.color" dark>
                        <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                    </v-toolbar>
                  <v-col class="pt-4 px-3 pb-0">
                  <v-text-field hide-details v-model="selectedEvent.name" :label="$t('appointment-title')" outlined dense filled style="border-radius: 12px"></v-text-field>
                  </v-col>
                  <v-col class="pa-2 px-3 pb-0">
                  <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto" title="">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field hide-details v-model="appointmentDate" :label="$t('appointment-date')" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined dense filled style="border-radius: 12px"></v-text-field>
                    </template>
                    <v-date-picker v-model="appointmentDate" no-title scrollable  :min="getMinDate()"  @change="menu2 = false" ></v-date-picker>
                  </v-menu>
                  </v-col>
                  <v-col class=" pl-1">
                    <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" :nudge-right="40" :return-value.sync="appointmentTime" transition="scale-transition" offset-y max-width="290px" min-width="290px" :title="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field hide-details v-model="appointmentTime" :label="$t('appointment-time')" prepend-inner-icon="mdi-clock-outline" readonly v-bind="attrs" v-on="on" outlined dense filled style="border-radius: 12px" class="pl-2"></v-text-field>
                      </template>
                      <v-time-picker v-if="menu1" v-model="appointmentTime" :min="getMinTime()" full-width @click:minute="$refs.menu1.save(appointmentTime)" no-title format="ampm"></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col class=" pa-2 px-3">
                    <v-textarea hide-details v-model="selectedEvent.description" :label="$t('description')" outlined dense filled style="border-radius: 12px"></v-textarea>
                  </v-col>
<!--                    <v-card-text>-->
<!--                        <span v-html="selectedEvent.details"></span>-->
<!--                    </v-card-text>-->
                    <v-card-actions class="d-flex justify-space-between ">
                        <v-btn class="table-cancel-button" @click="selectedOpen = false">
                          <span color="black--text" class="pl-1">{{ $t("cancel") }}</span>
                        </v-btn>
                      <v-btn text class="table-create-button" @click="saveChanges">
                        <span class="pl-2">{{ $t("save") }}</span>
                      </v-btn>
                    </v-card-actions>
                </v-card>
                </v-menu>
            </v-sheet>
        </v-col>
    </v-row>
</template>

<script>
import { mapState } from 'vuex';
// import { formatterFilter } from '@/utils/luxon-formater';

export default {
	props: ['doctor'],
	data: () => ({
		loading: false,
		doctorId: null,
		focus: '',
		menu1: false,
		menu2: false,
		appointmentDescription: '',
		appointmentTitle: '',
		appointmentDate: '',
		appointmentTime: '',
		type: 'month',
		typeToLabel: {
			month: 'Month',
			week: 'Week',
			day: 'Day',
		},
		selectedEvent: {},
		selectedElement: null,
		selectedOpen: false,
		events: [],
		colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
		names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
		language: 'en',
	}),
	computed: {
		...mapState({
			loggedUser: state => state.authentication.userData,
		}),
	},
	watch: {
		async doctor (val) {
			this.loading = true;
			await this.getDoctorAppointments(val);
		},
	},
	async mounted () {
		this.setLanguage(this.loggedUser.language);
		this.labelLanguage();
		this.$refs.calendar.checkChange();
		if (this.loggedUser.roleId === 3) {
			this.doctorId = this.loggedUser.id;
			await this.getDoctorAppointments(undefined, this.loggedUser.id);
		}
	},
	methods: {
		setLanguage (lang) {
			switch (lang) {
			case 'ENG':
				this.language = 'en';
				break;
			case 'ESP':
				this.language = 'es';
				break;
			default:
				this.language = 'en';
				break;
			}
		},
		labelLanguage () {
			this.typeToLabel.month = this.$t('month');
			this.typeToLabel.week = this.$t('week');
			this.typeToLabel.day = this.$t('day');
		},
		viewDay ({ date }) {
			this.focus = date;
			this.type = 'day';
		},
		getEventColor (event) {
			return event.color;
		},
		setToday () {
			this.focus = '';
		},
		getMinDate () {
			const date = new Date();
			return date.toISOString().split('T')[0];
		},
		getMinTime () {
			var date = new Date();
			if (this.appointmentDate === this.getMinDate()) {
				return `${date.getHours() + ':' + date.getMinutes()}`;
			}
		},
		prev () {
			this.$refs.calendar.prev();
		},
		saveChanges () {
			const body = {
				appointmentId: this.selectedEvent.appointmentId,
				appointmentDateTime: this.appointmentDate.concat('T').concat(this.appointmentTime),
				appointmentDurationInMin: 0,
				appointmentTitle: this.selectedEvent.name,
				appointmentDescription: this.selectedEvent.description,
			};
			this.$store.dispatch('appointments/updateAppointments', body);
		},
		next () {
			this.$refs.calendar.next();
		},
		showEvent ({ nativeEvent, event }) {
			const open = () => {
				this.selectedEvent = event;
				this.selectedElement = nativeEvent.target;
				// requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true));
			};

			if (this.selectedOpen) {
				this.selectedOpen = false;
				requestAnimationFrame(() => requestAnimationFrame(() => open()));
			} else {
				open();
			}

			nativeEvent.stopPropagation();
		},
		async getDoctorAppointments (doctor, doctorId) {
			if (doctor || doctorId) {
				const events = [];
				const appointments = await this.$store.dispatch('appointments/getAppointmentsForDoctor', doctor || doctorId);
				if (appointments.length > 0) {
					appointments.forEach(appointment => {
						const first = new Date(appointment.appointmentDateTime);
						const secondTimestamp = appointment.appointmentPlannedDurationInMin * 60000;
						const second = new Date(first.getTime() + secondTimestamp);
						events.push({
							name: appointment.appointmentTitle,
							description: appointment.appointmentDescription,
							// date: formatterFilter(new Date(appointment.appointmentDateTime).toISOString(), 'date'),
							// time: formatterFilter(new Date(appointment.appointmentDateTime).toISOString(), 'time'),
							start: new Date(appointment.appointmentDateTime),
							end: second,
							color: this.colors[this.rnd(0, this.colors.length - 1)],
							timed: true,
							appointmentId: appointment.appointmentId,
						});
					});
				}
				this.events = events;
				this.loading = false;
				return;
			}
			this.events = [];
		},
		rnd (a, b) {
			return Math.floor((b - a + 1) * Math.random()) + a;
		},
	},
};
</script>
